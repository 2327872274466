.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: zeroBlink 10s linear infinite;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes zeroBlink {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

:root {
  overflow-x: hidden;
  background-color: black;
}

@keyframes moveRightToLeft {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }

  65% {
    opacity: opacity 0.8;
  }
  85% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes moveRightToLeft2 {
  0% {
    transform: translateX(80%);
  }
  100% {
    transform: translateX(-600%);
  }
}

.testImage1 {
  position: absolute;
  bottom: 20px;
  left: 75%;
  animation: moveRightToLeft 9900ms linear infinite;
  width: 25%;
  border-radius: 100px;
  animation-iteration-count: infinite;
  z-index: 4;
}
.testImage2 {
  position: absolute;
  width: 35%;
  max-height: 35%;
  top: 45px;
  z-index: 4;
  border-radius: 100px;
  animation: moveRightToLeft 9900ms linear infinite;
  animation-iteration-count: infinite;
  border-radius: 100px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.testImage3 {
  position: absolute;
  width: 30%;
  bottom: 25px;
  z-index: 4;
  animation: moveRightToLeft 9900ms linear infinite;

  border-radius: 100px;
}

.testImage4 {
  position: absolute;
  width: 30%;
  top: 150px;
  left: 75%;
  z-index: 4;
  animation: moveRightToLeft 9900ms linear infinite;

  border-radius: 100px;
}

.testImage5 {
  position: absolute;
  width: 30%;
  top: 250px;
  right: 76%;
  z-index: 2;
  animation: moveRightToLeft 9900ms linear infinite;

  filter: blur(25px);

  border-radius: 100px;
}

.testImage6 {
  position: absolute;
  width: 30%;
  bottom: 250px;
  left: 75%;
  z-index: 2;
  animation: moveRightToLeft 9900ms linear infinite;
  filter: blur(25px);
  border-radius: 100px;
}

.testImage7 {
  position: absolute;
  width: 30%;
  top: 25px;
  left: 25%;
  z-index: 2;
  animation: moveRightToLeft 9900ms linear infinite;

  filter: blur(25px);

  border-radius: 100px;
}

.sidebar {
  animation: blink 10s ease-out 1;
}

.background {
  overflow-x: hidden;
  background-image: url('../public/backgroundMain.jpg');
  background-position: center;
  background-repeat: no-repeat;

  background-size: cover;
  position: relative;
}

@keyframes colorchange {
  0% {
    color: red;
  }
  50% {
    color: #e1ff00;
  }
  100% {
    color: rgb(4, 162, 27);
  }
}

.loaderContainer {
  animation: zeroBlink 10s ease-out 1;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveLeftToRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.company-name-nav {
  font-size: 1.5em;
  color: white;
  font-weight: bold;
  text-decoration: none;
  margin-left: 10px;

  animation: moveLeftToRight 3s ease-out 1;
  z-index: -500;
}

.loginContainer {
  z-index: 5;
  animation: zeroBlink 10s linear 1;
}

.navbarContainer {
  z-index: 6;
}
